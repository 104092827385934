import { StoreTypes } from 'common';
import { useInjection } from 'inversify-react';

import { AsyncHookResult, InjectableHook } from 'domain/hooks';

export type IGetFoodItemListHook = InjectableHook<AsyncHookResult<StoreTypes.Product, unknown>>;

export const GetFoodItemList = Symbol('GetFoodItemList');

export type IGetFoodItemList = InjectableHook<
  AsyncHookResult<StoreTypes.ProductResponse, unknown> & {
    fetchFoodItemList: () => void;
  }
>;

const useCaseGetFoodItemList = () => {
  const adapter = useInjection<IGetFoodItemList>(GetFoodItemList);
  const { error, result, inProgress, fetchFoodItemList } = adapter();

  return {
    inProgress,
    error,
    result: result?.products,
    fetchFoodItemList,
  };
};

export default useCaseGetFoodItemList;
