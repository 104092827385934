import { IGetFoodItemListHook } from 'application/modules/bookingWizard/useCases/hooks/useCaseGetFoodItemList';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';

const useGetFoodItemList: IGetFoodItemListHook = () => {
  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch: fetchFoodItemList,
  } = mboAPI.useGetFoodItemListQuery({});

  return {
    inProgress: isLoading || isFetching,
    error,
    result: data,
    fetchFoodItemList,
  };
};

export default useGetFoodItemList;
