import { enAU, enGB, enUS } from 'date-fns/locale';

const USDollarsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const AEDollarsFormatter = new Intl.NumberFormat('en-AE', {
  style: 'currency',
  currency: 'AED',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  currencyDisplay: 'code',
});

export const isAustralia = () => import.meta.env.VITE_SENTRY_ENV.endsWith('_au');
export const isUAE = () => import.meta.env.VITE_SENTRY_ENV.endsWith('_ae');

export enum RegionType {
  Australia = 'australia',
  UAE = 'uae',
  USA = 'usa',
}
export const Region: RegionType = isAustralia()
  ? RegionType.Australia
  : isUAE()
  ? RegionType.UAE
  : RegionType.USA;

export const formatToCurrency = (price: string | number | undefined) => {
  if (price === 0) {
    return isUAE() ? '0 AED' : '$0';
  }
  if (!price) {
    return null;
  }
  if (typeof price === 'string') {
    return isUAE()
      ? AEDollarsFormatter.format(Number(price)).replace(/^([A-Z]{3})\s*(.+)$/, '$2 $1')
      : USDollarsFormatter.format(Number(price));
  }
  if (typeof price === 'number') {
    return isUAE()
      ? AEDollarsFormatter.format(price).replace(/^([A-Z]{3})\s*(.+)$/, '$2 $1')
      : USDollarsFormatter.format(price);
  }
};

export const formatPhoneNumber = (number: string | undefined) => {
  if (!number) {
    return '';
  }
  if (isAustralia()) {
    return number.replace(/\D+/g, '').replace(/([0-9]{2})([0-9]{4})([0-9]{4}$)/gi, '($1) $2 $3');
  }
  if (isUAE()) {
    const uaeNumber = number.startsWith('971') ? number.substring(3) : number;
    if (uaeNumber.startsWith('4')) {
      // Dubai landline
      return '+971 4 ' + uaeNumber.substring(1, 4) + ' ' + uaeNumber.substring(4);
    } else if (uaeNumber.match(/^[2679]/)) {
      // Other Emirates landline
      return (
        '+971 ' +
        uaeNumber.substring(0, 1) +
        ' ' +
        uaeNumber.substring(1, 4) +
        ' ' +
        uaeNumber.substring(4)
      );
    } else if (number.startsWith('5')) {
      // Mobile numbers
      return (
        '+971 5' +
        uaeNumber.substring(1, 2) +
        ' ' +
        uaeNumber.substring(2, 5) +
        ' ' +
        uaeNumber.substring(5)
      );
    } else {
      return uaeNumber;
    }
  }
  return number.replace(/\D+/g, '').replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, '($1) $2-$3');
};

export const isCardTypeAmex = (cardNumber: string) =>
  cardNumber?.indexOf('34') === 0 || cardNumber?.indexOf('37') === 0;

export const findDebitCardType = (cardNumber: string) => {
  if (isCardTypeAmex(cardNumber)) {
    return '0000-000000-00000';
  } else {
    return '0000-0000-0000-0000';
  }
};

export const getAdapterLocale = () => (isAustralia() ? enAU : isUAE() ? enGB : enUS);

export const isUS = isAustralia() || isUAE() ? false : true;

export function parseStaffId(staffId: string | number | undefined): number[] {
  if (!staffId) return [];

  let input = staffId;

  if (typeof input === 'number') {
    input = input.toString();
  }

  return input
    .split(',')
    .map((s) => parseFloat(s.trim()))
    .filter((num) => !isNaN(num));
}

export const CANCEL_WINDOW_HRS = isUAE() ? 24 : 12;
