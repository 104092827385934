import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { WizardStep } from 'domain/entities/WizardStep';
import CheckYourEmailStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/CheckYourEmailStep';
import ChooseATimeStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/ChooseATimeStep';
import ConfirmReservationStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/ConfirmReservationStep';
import CreateAccountStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/CreateAccountStep';
import MobilePricingStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/MobilePricingStep';
import PaymentDetailsStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/PaymentDetailsStep';
import ReservationCompleteStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/ReservationCompleteStep';
import ReservationDateStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/ReservationDateStep';
import SelectExperienceStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SelectExperienceStep';
import SelectInstructorStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SelectInstructorStep';
import SelectLocationStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SelectLocationStep';
import SessionLengthStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep';
import SignInStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SignInStep';
// import PreOrderFoodStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep';
import BookingWizardContainer from 'infrastructure/targets/web/modules/bookingWizard/components/BookingWizardContainer';

const BookingWizard: FC = () => {
  const params = useParams();
  const { stepName } = params;

  const [currentStep, setCurrentStep] = useState(
    (stepName as WizardStep) || WizardStep.selectLocation,
  );

  useEffect(() => {
    setCurrentStep(stepName as WizardStep);
  }, [stepName]);

  return (
    <>
      <BookingWizardContainer currentStep={currentStep as WizardStep}>
        <SelectLocationStep stepName={WizardStep.selectLocation} />
        <SelectExperienceStep stepName={WizardStep.selectExperience} />
        <SelectInstructorStep stepName={WizardStep.selectInstructor} />
        <ReservationDateStep stepName={WizardStep.reservationDate} />
        <ChooseATimeStep stepName={WizardStep.chooseLessonTime} />
        <SessionLengthStep stepName={WizardStep.sessionLength} />
        <SignInStep stepName={WizardStep.checkoutSignIn} />
        <CreateAccountStep stepName={WizardStep.createAccount} />
        <CheckYourEmailStep stepName={WizardStep.checkoutCheckYourEmail} />
        <PaymentDetailsStep stepName={WizardStep.paymentDetails} />
        <ConfirmReservationStep stepName={WizardStep.confirmReservation} />
        <ReservationCompleteStep stepName={WizardStep.seeYouSoon} />
        <MobilePricingStep stepName={WizardStep.pricing} />
        {/* <PreOrderFoodStep stepName={WizardStep.preOrderFood} /> */}
      </BookingWizardContainer>
    </>
  );
};

export default BookingWizard;
