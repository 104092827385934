import AsyncStorage from '@react-native-async-storage/async-storage';
import { Action, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';

import mboAPI, { rtkQueryErrorLogger } from 'infrastructure/redux/adapters/mboAPI/mboApi';
import authSlice from 'infrastructure/redux/slices/auth.slice';
import bookingWizardSlice from 'infrastructure/redux/slices/bookingWizard.slice';
import paymentSlice from 'infrastructure/redux/slices/payment.slice';
import remoteConfigSlice from 'infrastructure/redux/slices/remoteConfig.slice';
import themeSlice from 'infrastructure/redux/slices/theme.slice';
import userSlice from 'infrastructure/redux/slices/user.slice';

declare global {
  const REDUX_STORE_VERSION: string;
}

export const getReduxStoreVersion = () => {
  try {
    return REDUX_STORE_VERSION;
  } catch (error) {
    return 'root';
  }
};

const persistConfig: PersistConfig<{
  key: string;
  storage: typeof AsyncStorage;
  whitelist: string[];
}> = {
  key: getReduxStoreVersion() || 'root',
  storage: AsyncStorage,
  blacklist: ['router'],
};

const rootReducer = combineReducers({
  theme: themeSlice,
  bookingForm: bookingWizardSlice,
  auth: authSlice,
  paymentForm: paymentSlice,
  user: userSlice,
  remoteConfig: remoteConfigSlice,
  [mboAPI.reducerPath]: mboAPI.reducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configureRootReducer: Reducer<any, Action<any>> = persistReducer(
  persistConfig,
  (state: RootState, action) => rootReducer(state, action),
);

const store = configureStore({
  reducer: configureRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(mboAPI.middleware)
      .concat(rtkQueryErrorLogger),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
