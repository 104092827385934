import * as Sentry from '@sentry/browser';
import { UserTypes } from 'common';
import { useEffect, useMemo, useState } from 'react';

import {
  IVerifyUserAdapter,
  UserVerificationError,
} from 'application/modules/auth/useCases/hooks/useCaseVerifyUser';
import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { WizardStep } from 'domain/entities/WizardStep';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import { selectAccessToken, selectRefreshToken } from 'infrastructure/redux/slices/auth.selector';
import {
  clearAuthData,
  setAccessToken,
  setRefreshToken,
  setShowError,
  setToken,
} from 'infrastructure/redux/slices/auth.slice';
import {
  selectBookingForm,
  selectEmail,
  selectLocation,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setBookingWizardData, setEmail } from 'infrastructure/redux/slices/bookingWizard.slice';
import { setUserSummary } from 'infrastructure/redux/slices/user.slice';
import { getReduxStoreVersion } from 'infrastructure/redux/store';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';

import { QueryOrSerializedError } from './SignInAdapter';

export type Vgo = (method: string, context?: string) => void;

declare global {
  const vgo: Vgo | undefined;
}

const getError = (error?: QueryOrSerializedError): UserVerificationError | undefined => {
  if (!error) {
    return undefined;
  }

  if (error?.status === 401) {
    return UserVerificationError.Unauthorized;
  }

  if (error?.status === 400) {
    return UserVerificationError.ValidationError;
  }

  return UserVerificationError.Unknown;
};

const useVerifyUser: IVerifyUserAdapter = () => {
  const location = useAppSelector(selectLocation);
  const storeEmail = useAppSelector(selectEmail);
  const dispatch = useAppDispatch();
  const { navigateToCheckYourEmailPage, navigateToPage } = useCaseExternalNavigation();
  const [verifyUser, { isLoading, data, error }] = mboAPI.useLazyVerifyUserQuery();
  const [getUser, { isLoading: isUserDataLoading }] = mboAPI.useLazyGetUserDataQuery();
  const bookingFormData = useAppSelector(selectBookingForm);

  const [getBookingData] = mboAPI.useLazyRetrieveBookingDataQuery();
  const [locationId, setLocationId] = useState<string | undefined>(location);
  const accessToken = useAppSelector(selectAccessToken);
  const refreshToken = useAppSelector(selectRefreshToken);

  const isAlreadyLoggedIn = useMemo(
    () => !!(accessToken && refreshToken),
    [accessToken, refreshToken],
  );

  useEffect(() => {
    setLocationId(location);
  }, [location]);

  const logoutSignedInUser = () => {
    dispatch(clearAuthData());
    localStorage.removeItem(`persist:${getReduxStoreVersion()}`);
  };

  const openMobileApp = (
    token: string | null,
    email: string | null,
    bookingUUID?: string | null,
  ) => {
    try {
      const appScheme = process.env.VITE_MOBILE_APP_SCHEME;
      if (appScheme) {
        if (window.confirm('Open the Five Iron app to finish verifying your account.')) {
          window.location.href = `${appScheme}://root/verify?token=${token}&email=${email}&uuid=${bookingUUID}`;
        }
      }
    } catch (error) {
      console.error(error);
      navigateToPage('/');
    }
  };

  const handleVerifyUser = (
    token: string | null,
    email: string | null,
    bookingUUID?: string | null,
    useMobile?: boolean,
  ) => {
    if (useMobile) {
      return openMobileApp(token, email, bookingUUID);
    }

    if (bookingUUID && bookingUUID !== 'undefined') {
      handleParseAndSaveBookingData(bookingUUID);
    }

    const userEmail = email ? email : storeEmail;
    if (token && userEmail) {
      dispatch(setToken(token));
      dispatch(setEmail({ email: decodeURIComponent(userEmail) }));
      verifyUser({ token, email: userEmail });

      // https://tagmanager.google.com/#/container/accounts/6112801006/containers/123456338/workspaces/10
      if (typeof vgo !== 'undefined') {
        vgo('setEmail', userEmail);
        vgo('process');
      }
      Sentry.setUser({ email: userEmail });
    } else {
      navigateToCheckYourEmailPage(error && error);
    }
  };
  const handleParseAndSaveBookingData = async (bookingUUID: string) => {
    try {
      const retrievedBookingData = await getBookingData(bookingUUID).unwrap();
      setLocationId(retrievedBookingData.location);
      dispatch(setBookingWizardData(retrievedBookingData));
    } catch (error) {
      // eslint-disable-next-line no-console
      dispatch(setShowError(true));
    }
  };
  const handleAccessToken = () => {
    if (data?.accessToken) {
      dispatch(setAccessToken(data?.accessToken));
    }
    if (data?.refreshToken) {
      dispatch(setRefreshToken(data?.refreshToken));
    }
  };

  const handleSaveUserDataToStore = (userSummary: UserTypes.UserSummary | undefined) => {
    dispatch(setUserSummary(userSummary));
  };

  const handleGetUser = () => {
    if (locationId) {
      return getUser(locationId).unwrap();
    }
  };

  const handleRedirectToCheckYourEmail = (error: UserVerificationError) => {
    navigateToCheckYourEmailPage(error && error);
  };

  const handleRedirectToConfirmReservation = () => {
    navigateToPage(`/${WizardStep.confirmReservation}`);
  };

  const handleNavigateToPaymentDetails = () => {
    navigateToPage(`/${WizardStep.paymentDetails}`);
  };

  const handleNavigateToSelectExperience = () => {
    navigateToPage(`/${WizardStep.selectExperience}`);
  };

  const handleNavigateToLocationSelect = () => {
    navigateToPage(`/${WizardStep.selectLocation}`);
  };

  return {
    inProgress: isLoading,
    result: data,
    error: getError(error),
    isUserDataLoading,
    isAlreadyLoggedIn,
    handleSaveUserDataToStore,
    handleGetUser,
    handleAccessToken,
    redirectToConfirmReservation: handleRedirectToConfirmReservation,
    redirectToCheckYourEmail: handleRedirectToCheckYourEmail,
    redirectToPaymentDetails: handleNavigateToPaymentDetails,
    redirectToSelectExperience: handleNavigateToSelectExperience,
    redirectToLocationSelect: handleNavigateToLocationSelect,
    verifyUser: handleVerifyUser,
    logoutSignedInUser,
    bookingFormData,
  };
};

export default useVerifyUser;
