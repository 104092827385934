import { MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import {
  AppointmentTypes,
  AuthDto,
  AvailabilityTypes,
  CalendarEventTypes,
  LocationTypes,
  PaymentDto,
  PromoCodeTypes,
  UserTypes,
} from 'common';

import { LoginWithVerificationTokenOutputBoundary } from 'application/modules/auth/useCases/hooks/useCaseVerifyUser';
import { BookAppointmentValues } from 'application/modules/bookingWizard/useCases/hooks/useCaseConfirmReservation';
import { setErrorStatus, setShowError } from 'infrastructure/redux/slices/auth.slice';

import baseApiWithReauth from './baseApiWithReauth';
import {
  AppointmentRoutes,
  AuthRoutes,
  FoodOrderRoutes,
  LocationRoutes,
  ScheduleRoutes,
} from './mboApiRoutes';

import type { Middleware } from '@reduxjs/toolkit';

const errorStatusCodesToLog = [400, 500, 501, 502, 503, 504];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stringifyRecursively = (_key: string, value: any) => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value, stringifyRecursively);
  }
  return value;
};

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const errorStatusCode = action?.payload?.status;
  next(action); // This add because requests were stuck on pending state on error without it
  if (isRejectedWithValue(action)) {
    const isStatusCodeToLog = errorStatusCode > 399;

    if (isStatusCodeToLog) {
      const { method, url } = action?.meta?.baseQueryMeta?.request;
      const requestBody = JSON.stringify(
        action?.meta?.arg?.originalArgs?.body,
        stringifyRecursively,
      );
      const responseBody = action?.payload?.data;

      api.dispatch(setErrorStatus(errorStatusCode));
      api.dispatch(setShowError(true));

      Sentry.withScope((scope) => {
        scope.setExtra('Request method', method);
        scope.setExtra('Request URL', url);
        scope.setExtra('Request body', requestBody);
        scope.setExtra('Response code', errorStatusCode);
        scope.setExtra('Response body', responseBody);

        Sentry.captureException(new Error(JSON.stringify(action?.payload?.data?.message)));
      });
    }
  }

  return next(action);
};

const mboAPI = baseApiWithReauth.injectEndpoints({
  endpoints: (builder) => ({
    getSimulatorLocations: builder.query<Array<LocationTypes.SimulatorLocation>, void>({
      query: () => LocationRoutes.getLocations(),
    }),
    getAvailableSimulatorRentalAppointments: builder.query<
      Array<AvailabilityTypes.SimulatorAppointmentAvailability>,
      {
        locationId: string;
        startDateTime?: string;
        endDateTime?: string;
        email?: string;
        partySize: string;
      }
    >({
      query: ({ locationId, startDateTime, endDateTime, email, partySize }) =>
        AppointmentRoutes.getAvailableSimulatorRentalAppointments(
          locationId,
          partySize,
          startDateTime,
          endDateTime,
          email,
        ),
      providesTags: ['SimulatorAppointments'],
    }),
    getAvailableSimulatorRentalDates: builder.query<
      Array<string>,
      { locationId: string; startDateTime?: string; endDateTime?: string }
    >({
      query: ({ locationId, startDateTime, endDateTime }) =>
        AppointmentRoutes.getAvailableSimulatorRentalDates(locationId, startDateTime, endDateTime),
      providesTags: ['SimulatorAppointmentsDates'],
    }),
    getAppointmentPricing: builder.mutation<
      AppointmentTypes.ServicePricing[],
      AppointmentTypes.AppointmentPricingRequest
    >({
      query: (body) => ({
        url: AppointmentRoutes.getAppointmentPricing(),
        method: 'POST',
        body,
      }),
    }),
    getClientAppointments: builder.mutation<AppointmentTypes.ClientAppointmentResponse[], void>({
      query: () => AppointmentRoutes.getClientAppointments(),
    }),
    getUpcomingAppointments: builder.query<AppointmentTypes.ClientAppointmentResponse[], void>({
      query: () => AppointmentRoutes.getUpcomingAppointments(),
    }),
    getHourlySimulatorPricing: builder.mutation<
      AppointmentTypes.HourlySimulatorPricingDto,
      AppointmentTypes.HourlySimulatorPricingRequestDto
    >({
      query: (body) => ({
        url: AppointmentRoutes.getHourlySimulatorPricing(),
        method: 'POST',
        body,
      }),
    }),
    cancelAppointment: builder.mutation<
      AppointmentTypes.CanceledAppointmentResponse,
      { locationId: string; body: AppointmentTypes.CancelAppointmentRequest }
    >({
      query: ({ locationId, body }) => ({
        url: AppointmentRoutes.cancelAppointment(locationId),
        method: 'POST',
        body,
      }),
    }),
    getAvailableFirstTimeLessonsAppointments: builder.query<
      Array<AvailabilityTypes.FirstTimeLessonAppointmentAvailability>,
      { locationId: string; startDateTime?: string; endDateTime?: string; email?: string }
    >({
      query: ({ locationId, startDateTime, endDateTime, email }) =>
        AppointmentRoutes.getAvailableFirstLessonAppointments(
          locationId,
          startDateTime,
          endDateTime,
          email,
        ),
      providesTags: ['FirstLessonAppointments'],
    }),
    getAvailableLessonAppointments: builder.query<
      Array<AvailabilityTypes.LessonAppointmentAvailability>,
      {
        locationId: string;
        startDateTime?: string;
        endDateTime?: string;
        staffIds?: number;
        email?: string;
      }
    >({
      query: ({ locationId, startDateTime, endDateTime, staffIds, email }) =>
        AppointmentRoutes.getAvailableLessonAppointments(
          locationId,
          startDateTime,
          endDateTime,
          staffIds,
          email,
        ),
      providesTags: ['LessonAppointments'],
    }),
    verifyPromoCode: builder.query<
      PromoCodeTypes.PromoCodeVerificationResponse,
      { siteId: string; promoCode: string; date: string }
    >({
      query: ({ siteId, promoCode, date }) =>
        AppointmentRoutes.verifyPromoCode(siteId, promoCode, date),
    }),
    getDailyPromoCode: builder.query<
      PromoCodeTypes.DailyPromoCodeResponse,
      { siteId: string; date: string }
    >({
      query: ({ siteId, date }) => AppointmentRoutes.getDailyPromoCode(siteId, date),
    }),
    getAvailableLessonDates: builder.query<
      Array<string>,
      { locationId: string; startDateTime?: string; endDateTime?: string }
    >({
      query: ({ locationId, startDateTime, endDateTime }) =>
        AppointmentRoutes.getAvailableLessonDates(locationId, startDateTime, endDateTime),
      providesTags: ['LessonAppointmentsDates'],
    }),
    logInWithEmail: builder.mutation<
      void,
      { email: string; locationId: string; bookingUUID?: string }
    >({
      query: (body) => ({
        url: AuthRoutes.loginWithEmail(),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    logInWithProvider: builder.mutation<AuthDto.LoginWithProviderResponse, { idToken: string }>({
      query: (body) => ({
        url: AuthRoutes.loginWithProvider(),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    registerPushNotificationToken: builder.mutation<void, { token: string | null }>({
      query: (body) => ({
        url: AuthRoutes.registerPushToken(),
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    mobileLogInWithEmail: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: AuthRoutes.loginWithEmail(),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    createAccount: builder.mutation<
      void,
      { registerValues: AuthDto.RegisterDto; bookingUUID?: string }
    >({
      query: ({ registerValues, bookingUUID }) => ({
        url: AuthRoutes.createAccount(),
        method: 'POST',
        body: {
          ...registerValues,
          bookingUUID,
        },
      }),
    }),
    bookAppointment: builder.mutation<
      AppointmentTypes.BookedAppointmentResponse,
      BookAppointmentValues
    >({
      query: ({ locationId, body }) => ({
        url: AppointmentRoutes.bookAppointment(locationId),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SimulatorAppointments', 'LessonAppointments', 'FirstLessonAppointments'],
    }),
    sendWaiverLinksToGuests: builder.mutation<
      void,
      { locationId: string; body: AppointmentTypes.BookingWaiverRequest }
    >({
      query: ({ locationId, body }) => ({
        url: AppointmentRoutes.sendWaiverLinksToGuests(locationId),
        method: 'POST',
        body,
      }),
    }),
    runSyncPrices: builder.mutation<void, { siteId: string }>({
      query: ({ siteId }) => ({
        url: ScheduleRoutes.runSyncPrices(siteId),
        method: 'POST',
        body: {},
      }),
    }),
    verifyUser: builder.query<
      LoginWithVerificationTokenOutputBoundary,
      { token: string; email: string; redirectToUrl?: string }
    >({
      query: ({ token, email, redirectToUrl = '' }) =>
        AuthRoutes.verifyUser(token, encodeURIComponent(email), redirectToUrl),
    }),
    getUserData: builder.query<UserTypes.UserSummary, string>({
      query: (locationId) => AuthRoutes.getUserData(locationId),
      providesTags: ['User'],
    }),
    updateUserData: builder.mutation<void, { updateValues: AuthDto.UpdateDto }>({
      query: ({ updateValues }) => ({
        url: AuthRoutes.updateUserData(),
        method: 'PUT',
        body: updateValues,
      }),
      invalidatesTags: ['User'],
    }),
    updatePaymentDetails: builder.mutation<
      void,
      { locationId: string; body: PaymentDto.ClientDetailsDto }
    >({
      query: ({ locationId, body }) => ({
        url: AuthRoutes.updateBilling(locationId),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    refreshAuthToken: builder.mutation<{ accessToken: string; refreshToken: string }, string>({
      query: (refreshToken) => ({
        url: AuthRoutes.refreshToken(),
        method: 'POST',
        body: { refreshToken },
      }),
    }),
    retrieveBookingData: builder.query<AppointmentTypes.AppointmentBooking, string>({
      query: (bookingUUID) => AppointmentRoutes.retrieveBookingData(bookingUUID),
    }),
    saveBookingData: builder.mutation<string, AppointmentTypes.AppointmentBooking>({
      query: (booking) => ({
        url: AppointmentRoutes.storeBookingData(),
        method: 'POST',
        responseHandler: 'text',
        body: booking,
      }),
    }),
    deleteAccount: builder.mutation<void, void>({
      query: () => ({
        url: AuthRoutes.deleteAccount(),
        method: 'DELETE',
      }),
    }),
    checkInForAppointments: builder.mutation<
      void,
      { locationId: string; appointmentIds: number[] }
    >({
      query: ({ locationId, appointmentIds }) => ({
        url: AppointmentRoutes.checkIn(locationId),
        method: 'POST',
        body: { appointmentIds: appointmentIds },
      }),
    }),
    getLocationEventsCalendar: builder.query<Array<CalendarEventTypes.CalendarEvent>, string>({
      query: (locationId) => LocationRoutes.getLocationEventsCalendar(locationId),
    }),
    getFoodItemList: builder.query<
      any,
      { squareLocationIds: string; categoryIds?: string; limit?: number; cursor?: string }
    >({
      query: ({ squareLocationIds, categoryIds, limit, cursor }) =>
        FoodOrderRoutes.getFoodItemList(squareLocationIds, categoryIds, limit, cursor),
      providesTags: ['FoodItemList'],
    }),
  }),
});

export default mboAPI;
