import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { add, endOfDay, format, isToday, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import {
  AppointmentError,
  AppointmentPricingError,
  ChooseATimeError,
  ChooseATimeForm,
  IChooseATimeAdapter,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseATimeForLesson';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import {
  selectExperienceType,
  selectInstructorDetails,
  selectLocation,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import {
  setAddons,
  setAppointments,
  setResourceId,
  setStartTimeAndSessionLength,
} from 'infrastructure/redux/slices/bookingWizard.slice';
import { setTotalAmountToPay } from 'infrastructure/redux/slices/payment.slice';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { formatPricing } from 'application/modules/bookingWizard/common/helpers';
import { formatToCurrency } from 'infrastructure/targets/web/modules/common/helpers';
function getError(
  error: FetchBaseQueryError | SerializedError | undefined,
): ChooseATimeError | undefined {
  if (!error) {
    return undefined;
  }
  return ChooseATimeError.Unknown;
}

function getPricingError(
  error: FetchBaseQueryError | SerializedError | undefined,
): AppointmentPricingError | undefined {
  if (!error) {
    return undefined;
  }
  return AppointmentPricingError.Unknown;
}

function getAppointmentError(
  error: FetchBaseQueryError | SerializedError | undefined,
): AppointmentError | undefined {
  if (!error) {
    return undefined;
  }
  return AppointmentError.Unknown;
}

const useChooseATimeHook: IChooseATimeAdapter = () => {
  const dispatch = useAppDispatch();
  const selectedLocationId = useAppSelector(selectLocation);
  const reservationDate = useAppSelector(selectReservationDate);
  const user = useAppSelector(selectUserSummary);
  const isReservationDateToday = reservationDate && isToday(new Date(reservationDate));

  const startDate =
    reservationDate &&
    (isReservationDateToday ? new Date(reservationDate) : startOfDay(new Date(reservationDate)));
  const endDate = reservationDate && endOfDay(new Date(reservationDate));

  const selectedInstructor = useAppSelector(selectInstructorDetails);
  const flowType = useAppSelector(selectExperienceType);

  const {
    data: firstTimeLessonAppointmentAvailabilities,
    isLoading: areFirstTimeLessonLoading,
    error: firstTimeLessonError,
    isFetching: areFirstTimeLessonFetching,
  } = mboAPI.useGetAvailableFirstTimeLessonsAppointmentsQuery(
    {
      locationId: selectedLocationId || '',
      startDateTime: startDate && format(startDate, 'yyyy-MM-dd'),
      endDateTime: endDate && format(endDate, 'yyyy-MM-dd'),
    },
    {
      skip: !selectedLocationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: availableLessonAppointments,
    isLoading: areAppointmentsLoading,
    error: appointmentError,
    isFetching: areAppointmentsFetching,
  } = mboAPI.useGetAvailableLessonAppointmentsQuery(
    {
      locationId: selectedLocationId || '',
      startDateTime: startDate && format(startDate, 'yyyy-MM-dd'),
      endDateTime: endDate && format(endDate, 'yyyy-MM-dd'),
      staffIds: selectedInstructor?.staffId,
    },
    {
      skip: !selectedLocationId || !selectedInstructor,
      refetchOnMountOrArgChange: true,
    },
  );

  const lessonTypes = selectedInstructor?.services
    ?.filter((service) => !/Intro/g.test(service.title))
    ?.sort((a, b) => a.price - b.price)
    ?.map((service) => ({
      title: service.title.trim().split(' ').slice(0, 2).join(' '),
      value: service.defaultTimeLength,
      additionalValue: service.sessionTypeId,
      subtitle: formatToCurrency(service.price),
    }));

  const availableFirstTimeLessonSlots = useMemo(
    () =>
      firstTimeLessonAppointmentAvailabilities?.map((item) => {
        const availabilities = item.availabilities;
        const time = item.time.toString();
        return {
          value: time,
          title: format(new Date(time.slice(0, -1)), 'hh:mm aa'),
          additionalValue: availabilities[0].staffId,
          isDisabled: !availabilities[0].staffId || availabilities[0].resourceIds.length === 0,
          secondaryValue: item.sessionTypeId,
          tertiaryValue: availabilities[0].resourceIds?.[0],
        };
      }),
    [firstTimeLessonAppointmentAvailabilities],
  );

  const [getPricing, result] = mboAPI.useGetAppointmentPricingMutation();

  const getAvailableLessonSlotButtons = (selectedLessonLength: number) => {
    const instructor = availableLessonAppointments?.find(
      (appt) => appt.staffId === selectedInstructor?.staffId,
    );
    const availabilities = instructor?.services?.find(
      (service) => service.defaultTimeLength === selectedLessonLength,
    )?.availabilities;

    const slotButtons = availabilities?.map((availability) => {
      const time = availability?.time.toString();

      return {
        value: time,
        title: format(new Date(time?.slice(0, -1)), 'hh:mm aa'),
        secondaryValue: availability?.resourceId,
      };
    });

    return Array.from(
      new Set(
        slotButtons?.map((slotButton) =>
          availabilities?.find((slot) => slot.time.toString() === slotButton.value),
        ),
      ),
    )?.map((availability) => {
      const time = availability?.time.toString() || '';
      return {
        value: time,
        title: format(new Date(time?.slice(0, -1)), 'hh:mm aa'),
        secondaryValue: availability?.resourceId,
      };
    });
  };

  const saveAppointmentToStore = (values: ChooseATimeForm) => {
    const resourceId = Number(values?.resourceId);
    const formattedAppointments = formatPricing(result.data);

    const startDateTime = values?.sessionHour;
    const endDateTime = add(new Date(startDateTime), {
      minutes: Number(values.lessonLength),
    }).toISOString();

    dispatch(
      setStartTimeAndSessionLength({
        startTime: startDateTime,
        endTime: endDateTime,
        sessionLength: Number(values.lessonLength) / 60,
      }),
    );
    dispatch(setResourceId({ resourceId }));
    dispatch(
      setAppointments({
        appointments: formattedAppointments.appointments?.map((value) => ({
          ...value,
          resourceId,
        })),
      }),
    );
    dispatch(setAddons(formattedAppointments.addOn));
    const total = formattedAppointments?.appointments.reduce(
      (total, item) => Number(total) + Number(item.price),
      0,
    );
    dispatch(setTotalAmountToPay({ total }));
  };

  const handleGetPricing = (values: ChooseATimeForm) => {
    const startDateTime = values?.sessionHour;
    const endDateTime = add(new Date(startDateTime), {
      minutes: Number(values.lessonLength),
    }).toISOString();
    const staffId = values.staffId;
    if (selectedLocationId && staffId) {
      getPricing({
        email: user?.email,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        locationId: selectedLocationId,
        sessionTypeId: values?.sessionTypeId,
        staffIds: [staffId],
      });
    }
  };
  return {
    result: firstTimeLessonAppointmentAvailabilities,
    inProgress: areFirstTimeLessonLoading || areFirstTimeLessonFetching,
    error: getError(firstTimeLessonError),
    pricingError: getPricingError(result.error),
    appointentPricing: result.data,
    isPricingLoading: result.isLoading,
    selectedInstructor,
    areAppointmentsLoading: areAppointmentsLoading || areAppointmentsFetching,
    appointmentError: getAppointmentError(appointmentError),
    lessonTypes,
    saveAppointmentToStore,
    getPricing: handleGetPricing,
    availableFirstTimeLessonSlots,
    getAvailableLessonSlotButtons,
    isFirstTimeLesson: flowType === BookingWizardFlowTypes.FirstLesson,
    userData: user,
  };
};

export default useChooseATimeHook;
