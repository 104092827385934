import { Variants } from 'common';

import { FormattedPricing } from './components/ReservationSummary';

const isAustralia = import.meta.env.VITE_SENTRY_ENV.endsWith('_au');
const isUae = import.meta.env.VITE_SENTRY_ENV.endsWith('_ae');
const isDuckpin = import.meta.env.VITE_VARIANT === Variants.DUCKPIN;

export const getAppointmentUnitPrice = (lineItems: FormattedPricing[] | undefined) =>
  lineItems?.reduce((total, item) => (total + item.price) / (item.duration / 60), 0);

export const getContactPageUrl = () =>
  isDuckpin
    ? isGenericDuckpin()
      ? 'https://fiveirongolf.com/events'
      : 'https://www.detroitduckpin.com/#info'
    : isAustralia
    ? 'https://fiveirongolf.au/pages/events'
    : isUae
    ? 'https://www.fiveirongolf.ae/pages/contact-1'
    : 'https://fiveirongolf.com/events';

export const isGenericDuckpin = () => {
  const hostname = window.location.hostname;
  return hostname.includes('dp.stage.booking') || hostname.includes('duckpin.fiveirongolf');
};
